<template>
  <el-container class="app-container">
    <el-header style="margin:10px 0px;font-size:20px;">{{ $t('title.BasicInformation') }}</el-header>
    <!-- <el-main style=""> -->
    <el-row class="row-center">
      <el-col :span="16">
        <el-form ref="ruleForm" :disabled="fordisabled" :label-position="labelPosition" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item :class="$i18n.locale" label="仓库名称" prop="warehouseName">
            <el-input v-model="ruleForm.warehouseName" :placeholder="$t('page.inputPlaceholder')" :disabled="Boolean($route.query.id)" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="仓库简称" prop="warehouseShortName">
            <el-input v-model="ruleForm.warehouseShortName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="仓库代码" prop="warehouseCode">
            <el-input v-model="ruleForm.warehouseCode" maxlength="50" show-word-limit :disabled="Boolean($route.query.id)" onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="仓库英文名">
            <el-input
              v-model="ruleForm.enWarehouseName"
              :placeholder="$t('page.inputPlaceholder')"
              @input="ruleForm.enWarehouseName= ruleForm.enWarehouseName.replace(/[^\A-Za-z.]/g,'')"
            />
          </el-form-item>
          <el-form-item label="组织机构" prop="organization">
            <GroupTree
              v-model="ruleForm.organization"
              filterable
              :placeholder="$t('page.selectPlaceholder')"
              @handleLabelChange="handleLabelChange"
            />
          </el-form-item>
          <el-form-item label="货主" prop="cargoOwnerId">
            <el-select
              v-model="ruleForm.cargoOwnerId"
              :placeholder="$t('page.selectPlaceholder')"
              filterable
              @change="seletCaroWwnerName"
            >
              <el-option
                v-for="vj in baseWareHouseData"
                :key="vj.id"
                :label="vj.cargoOwnerName"
                :value="vj.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="租户" prop="tenantIds">
            <el-select v-model="ruleForm.tenantIds" multiple clearable filterable>
              <el-option
                v-for="item in tenantListOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="独立核算">
            <el-radio-group v-model="ruleForm.independentAccounting" :disabled="selfAccounting == '1'">
              <el-radio label="0">{{ $t('title.Yes') }}</el-radio>
              <el-radio label="1">{{ $t('title.No') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="仓库职能">
            <el-checkbox-group v-model="ruleForm.warehouseFunctionArr">
              <el-checkbox label="0">补货</el-checkbox>
              <el-checkbox label="1">售卖</el-checkbox>
              <el-checkbox label="2">越库</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="所属站点" prop="siteCode">
            <el-select v-model="ruleForm.siteCode" placeholder="">
              <el-option v-for="item in siteData" :key="item.id" :value="item.siteCode+''" :label="item.siteCode" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属平台" prop="platformCode">
            <el-select v-model="ruleForm.platformCode" placeholder="">
              <el-option
                v-for="item in getDictionariesOpt('WAREHOUSE_PLATFORM_CODE')"
                :key="item.val"
                :value="item.val"
                :label="item.label"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="区域类型" prop="warehouseLocation">
            <el-select v-model="ruleForm.warehouseLocation" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="国内" :value="1" />
              <el-option label="国外" :value="2" />
            </el-select>
          </el-form-item>

          <el-form-item label="仓库类型" prop="warehouseType">
            <el-select v-model="ruleForm.warehouseType" :placeholder="$t('page.selectPlaceholder')">
              <el-option label="自管仓" :value="3" />
              <el-option label="第三方仓" :value="4" />
              <el-option label="平台仓" :value="5" />
              <el-option label="工厂仓" :value="6" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否物理仓">
            <el-radio-group v-model="ruleForm.isLogicWarehouse" @change="change">
              <el-radio label="1">{{ $t('title.Yes') }}</el-radio>
              <el-radio label="0">{{ $t('title.No') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.isLogicWarehouse==0" label="渠道" prop="channelsJson">
            <el-select v-model="ruleForm.channelsJson" placeholder="" multiple filterable clearable>
              <el-option
                v-for="vj in Channel"
                :key="vj.id"
                :label="vj.channelName"
                :value="`${vj.channelCode}/${vj.channelName}/${vj.channelType}`"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="时区">
            <el-input v-model="ruleForm.timeZoneName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="仓库关账日" prop="closingDate">
            <el-input-number v-model="ruleForm.closingDate" controls-position="right" :min="1" :max="31" :precision="0" />
          </el-form-item>
          <el-form-item v-show="ruleForm.isLogicWarehouse == '0'" label="关联仓" :prop="ruleForm.isLogicWarehouse === '0' ? 'parentWarehouseId' : '' ">
            <el-select v-model="ruleForm.parentWarehouseId" placeholder="">
              <el-option
                v-for="vj in logicData"
                :key="vj.id"
                :label="vj.warehouseName"
                :value="vj.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="所在地区"
            :class="$i18n.locale"
            prop="formAddress"
          >
            <ProvinceSelect ref="address" v-model="ruleForm.formAddress" filterable class="mb-3" @getLabel="getLabel" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="详细地址">
            <el-input v-model="ruleForm.address" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :label="$t('page.Linkman')" prop="contactStaff">
            <el-input v-model="ruleForm.contactStaff" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="联系电话" prop="contactPhone">
            <el-input v-model="ruleForm.contactPhone" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :class="$i18n.locale" label="联系邮箱">
            <el-input v-model="ruleForm.contactEmail" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="ruleForm.remark" type="textarea" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
          <el-form-item :label="$t('page.status')" prop="useStatus">
            <el-radio-group v-model="ruleForm.useStatus">
              <el-radio :label="1">{{ $t('page.enabled') }}</el-radio>
              <el-radio :label="2">{{ $t('page.disable') }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row class="grid-content" type="flex" justify="center">
            <el-button type="primary" style="width:100px" :disabled="disableSubmit" @click="submitForm">{{ $t('title.submit') }}</el-button>
          </el-row>

        </el-form>
      </el-col>
    </el-row>

    <!-- </el-main> -->
  </el-container>
</template>

<script>
import { tenantList } from '@/api/user'
import { queryAllSiteList, gitChannel } from '@/api/stock-out'
import { getCargoOwner, getAllWarehouse, addWarehouse, modifyWarehouse, queryWarehouseId } from '@/api/listSelection'
import GroupTree from '@/components/ListSelection/GroupTree'
import ProvinceSelect from '@/components/ListSelection/addresList'
import { Mixin } from '@/mixin/mixin.js'
export default {
  components: {
    GroupTree, ProvinceSelect
  },
  mixins: [Mixin],
  data() {
    return {
      stateList: [],
      selfAccounting: '0',
      labelPosition: 'right',
      fordisabled: false, // 禁用文本框
      showRow: false, // 切换显示input
      formAddress: [], // 定义一个标识 接受参数值
      disableSubmit: false,
      baseWareHouseData: [],
      logicData: [],
      siteData: [],
      ruleForm: {
        enWarehouseName: '',
        channelsJson: [],
        formAddress: [],
        organizationName: '', // 组织机构-名称
        tenantIds: [],
        baseCompanyId: '1', // 公司id 1
        warehouseName: '',
        warehouseShortName: '',
        warehouseCode: '',
        warehouseLocation: '', // 所属区域 1国内  2国外
        warehouseType: '', // 1代表公司仓 2代表供应商仓
        address: '',
        contactPhone: '',
        contactStaff: '',
        remark: '',
        useStatus: 1, // 1代表启用 2代表禁用
        id: '',
        stateId: '',
        provinceName: '',
        cityName: '',
        houseNumber: '',
        postCode: '',
        platformList: [],
        closingDate: undefined,
        contactEmail: '',
        independentAccounting: '0',
        warehouseFunctionArr: [],
        warehouseFunction: '',
        parentWarehouseId: '',
        cargoOwnerId: '',
        isLogicWarehouse: '0',
        siteCode: '',
        platformCode: '',
        organization: '',
        cargoOwnerName: ''
      },
      tenantListOptions: [],
      labels: {},
      rules: {
        warehouseName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        warehouseShortName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        warehouseCode: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        platformCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        warehouseLocation: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        warehouseType: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        tenantIds: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        useStatus: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        siteCode: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        organization: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        cargoOwnerId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        parentWarehouseId: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        formAddress: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        channelsJson: [{ required: true, message: this.$t('page.required'), trigger: 'change' }],
        closingDate: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }]
      },
      platformList: [],
      Channel: []
    }
  },
  computed: {
    get() {
      const params = Object.assign({}, this.ruleForm)
      params.stateId = params.formAddress[0] ? params.formAddress[0] : ''
      params.provinceId = params.formAddress[1] ? params.formAddress[1] : ''
      params.cityId = params.formAddress[2] ? params.formAddress[2] : ''
      params.countyId = params.formAddress[3] ? params.formAddress[3] : ''
      params.channelsJson = params.channelsJson.length && params.channelsJson.map(item => {
        const [channelCode = '', channelName = '', channelType = ''] = item.split('/')
        return { channelCode, channelName, channelType }
      }) || []
      params.channelsJson = JSON.stringify(params.channelsJson || [])
      const { length, last = length - 1 } = params.organization
      if (length) params.organization = params.organization[last]
      delete params.formAddress
      return params
    },
    // 生成对应的请求参数
    Parameter() {
      const { cargoOwnerName = '', cargoOwnerCode = '' } = this.baseWareHouseData.find(item => item.id === this.ruleForm.cargoOwnerId) || []
      return Object.assign({}, this.ruleForm, this.get, this.labels, { cargoOwnerName, cargoOwnerCode })
    }
  },
  mounted() {
    // 查看时 禁用for
    if (this.$route.query && this.$route.query.disabled) {
      this.fordisabled = true
    }
    this._queryWarehouseId()
    this._tenantList()
    this._getCargoOwner()
    this.getAllWarehouseData()
    this._querySiteList()
    this._gitChannel()
  },
  methods: {
    change(val) {
      if (Number(val) === 1) {
        this.ruleForm.channelsJson = []
      }
    },
    async _gitChannel() {
      const { datas: { records }} = await gitChannel()
      this.Channel = records
    },
    getLabel(val) {
      this.labels = val
    },
    handleLabelChange({ selfAccounting, name }) {
      this.ruleForm.organizationName = name
      if (selfAccounting !== null) {
        this.selfAccounting = selfAccounting
        this.ruleForm.independentAccounting = !selfAccounting ? '1' : '0'
      }
    },
    // 选择货主名
    seletCaroWwnerName(e) {
      const { cargoOwnerName } = this.baseWareHouseData.find(v => (v.id === e))
      cargoOwnerName && (this.ruleForm.cargoOwnerName = cargoOwnerName)
    },
    // 所属站点
    async _querySiteList() {
      const { datas } = await queryAllSiteList()
      this.siteData = datas
    },

    // 查看仓库All逻辑仓
    async getAllWarehouseData() {
      const params = {
        isLogicWarehouse: 1
      }
      const { datas, code } = await getAllWarehouse(params)
      if (!code) {
        this.logicData = datas
      }
    },
    // 查看-货主
    async _getCargoOwner() {
      const { datas, code } = await getCargoOwner()
      if (!code) {
        this.baseWareHouseData = datas
      }
    },
    getPlatformList(list) {
      this.platformList = list
    },
    // 获取仓库信息数据
    async _queryWarehouseId(query) {
      const { id } = this.$route.query
      if (id) {
        const { datas } = await queryWarehouseId(id)
        this.ruleForm = { ...datas, isLogicWarehouse: datas.isLogicWarehouse + '',
          warehouseFunctionArr: datas.warehouseFunction ? datas.warehouseFunction.split(',') : [],
          organization: datas.organization ? Number(datas.organization) : [], platformCode: datas.platformCode || ''
        }
        this.ruleForm.formAddress = [datas.stateId, datas.provinceId, datas.cityId, datas.countyId]
        console.log(this.ruleForm.formAddress)
        this.ruleForm.channelsJson = this.ruleForm.channelsJson && JSON.parse(this.ruleForm.channelsJson).map(item => {
          const { channelCode = '', channelName = '', channelType = '' } = item
          return `${channelCode}/${channelName}/${channelType}`
        })
        const arr = datas.tenantIdLists.split(',')
        for (let i = 0; i < arr.length; i++) {
          arr[i] = parseInt(arr[i])
        }
        this.ruleForm.tenantIds = arr
      }
    },
    // 租户信息获取
    async _tenantList() {
      const { datas } = await tenantList()
      this.tenantListOptions = datas
    },

    // 点击提交按钮将表单数据提交至后台
    submitForm(query) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const { id } = this.$route.query
          this.ruleForm.warehouseFunction = this.ruleForm.warehouseFunctionArr.join(',')
          if (id) {
            this._modifyWarehouse(this.Parameter)
          } else {
            this._addWarehouse(this.Parameter)
          }
        }
      })
    },
    async _addWarehouse(query) {
      try {
        this.disableSubmit = true
        const { msg, code } = await addWarehouse(this.Parameter)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
        } else { // 其他状态
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
      } finally {
        this.disableSubmit = false
      }
    },
    // 修改仓库信息
    async _modifyWarehouse(query) {
      try {
        this.labels = this.$refs.address.getLabel(this.ruleForm.formAddress)
        const { msg, code } = await modifyWarehouse(this.Parameter)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.$router.push({
            path: '../',
            append: true
          })
        } else { // 其他状态
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
      } finally {
        this.disableSubmit = false
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.dial_inp {
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(3n) {
    margin-left: 10px;
  }
  > div:nth-child(2) {
    margin-left: 10px;
  }
  > div:nth-child(5) {
    margin-left: 10px;
  }
  > div {
    width: 216px;
    margin-bottom: 5px;
  }
}
</style>

