<template>
  <div>
    <!-- 国家信息查询 -->
    <el-select :value="value[0]" filterable class="ml-1" placeholder="国家" :disabled="disabled" @input="Change($event,0)">
      <el-option
        v-for="item in state"
        :key="item.id"
        :label="item.stateName"
        :value="item.id"
      />
    </el-select>
    <!-- 省份信息查询 -->
    <el-select v-show="value[0]==1" :value="value[1]" filterable class="ml-1" placeholder="省份" :disabled="disabled" @input="Change($event,1)">
      <el-option
        v-for="item in province"
        :key="item.id"
        :label="item.provinceName"
        :value="item.id"
      />
    </el-select>
    <!-- 市信息查询 -->
    <el-select v-show="value[0]==1" :value="value[2]" filterable class="ml-1" placeholder="城市" :disabled="disabled" @input="Change($event,2)">
      <el-option
        v-for="item in city"
        :key="item.id"
        :label="item.cityName"
        :value="item.id"
      />
    </el-select>
    <!-- 区/县信息查询 -->
    <el-select v-show="value[0]==1" :value="value[3]" filterable class="ml-1" placeholder="区/县" :disabled="disabled" @input="Change($event,3)">
      <el-option
        v-for="item in county"
        :key="item.id"
        :label="item.countyName"
        :value="item.id"
      />
    </el-select>
  </div>
</template>

<script>
import { queryStateList } from '@/api/listSelection'
import { queryProvinceList } from '@/api/listSelection'
import { queryCityList } from '@/api/listSelection'
import { queryCountyList } from '@/api/listSelection'
export default {
  name: 'ProvinceSelect',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      state: [],
      province: [],
      city: [],
      county: [],
      temp: 1,
      isChina: true
    }
  },
  async mounted() {
    const { datas } = await queryStateList()
    this.state = datas
    this._queryProvinceList()
    this._queryCityList()
    this._queryCountyList()
    console.log(this.value)
    if (this.value.length && this.value[0] !== 1) this.isChina = false
  },
  methods: {
    getLabel(val, flag) {
      const [stateId = '', provinceId = '', cityId = '', countyId = ''] = val
      let stateName = ''
      let provinceName = ''
      let cityName = ''
      let countyName = ''
      if (stateId) {
        stateName = this.state.find(item => item.id === stateId).stateName
      }
      if (provinceId) {
        provinceName = this.province.find(item => item.id === provinceId).provinceName
      }
      if (cityId) {
        cityName = this.city.find(item => item.id === cityId).cityName
      }
      if (countyId) {
        countyName = this.county.find(item => item.id === countyId).countyName
      }
      const obj = { stateName, provinceName, cityName, countyName }
      if (flag) {
        this.$emit('getLabel', obj)
      } else {
        return obj
      }
    },
    Change(val, index) {
      this.value.splice(index, this.value.length - index)
      const arr = [].concat(this.value)
      if (index === 0) {
        this.isChina = false
        if (val === 1) this.isChina = true
        this._queryProvinceList(val)
        this.province = []
        this.city = []
        this.county = []
      }
      if (index === 1) {
        this._queryCityList(val)
        this.city = []
        this.county = []
      }
      if (index === 2) {
        this._queryCountyList(val)
        this.county = []
      }
      arr[index] = val
      this.value[index] = val
      this.$forceUpdate()
      this.$set(this.value, index, val)
      this.$emit('input', arr)
      this.getLabel(arr, true)
    },

    // 省份
    async _queryProvinceList(baseStateId) {
      // console.log({ baseStateId })
      const { datas } = await queryProvinceList({ baseStateId })
      this.province = datas
    },
    // 城市
    async _queryCityList(baseProvinceId) {
      const { datas } = await queryCityList({ baseProvinceId })
      this.city = datas
    },
    // 区/县
    async _queryCountyList(baseCityId) {
      const { datas } = await queryCountyList({ baseCityId })
      this.county = datas
    }
  }
}
</script>
